import { Button, Form, FormInstance, Input, Spin, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IVerifyEmailData } from '../types/verify-email/data.model';
import ModalAccountOnHold from './ModalAccountOnHold';
import ModalEmailAlreadyExists from './ModalEmailAlreadyExists';

interface Props {
  form: FormInstance<any>;
  className?: string;
  onPrevious: () => void;
  onNext: (setCheckingEmail: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

const Step2: React.FC<Props> = ({ className, form, onNext, onPrevious }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false);
  const [verifyingEmail, setVerifyingEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [modalEmailAlreadyExistsVisible, setModalEmailAlreadyExists] = useState<boolean>(false);
  const [modalAccountOnHoldVisible, setModalAccountOnHoldVisible] = useState<boolean>(false);
  const [data, setData] = useState<IVerifyEmailData | null>(null);
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const type = params.get('type');

  useEffect(() => {
    if (email !== '') {
      const timeout = setTimeout(() => {
        verifyEmail();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [email]);

  const verifyEmail = async () => {
    try {
      const email_errors = await form.getFieldError('email');
      if (email_errors.length == 0) {
        setVerifyingEmail(true);
        axios
          .post(`${process.env.REACT_APP_API_URL}/operations/verifyEmail`, undefined, {
            params: {
              email,
            },
          })
          .then(({ data }) => {
            setVerifyingEmail(false);
            setData(data);
            if (data.message == 'exists') {
              if (data.count == 1 && data.access.user && data.pending) {
                setModalAccountOnHoldVisible(true);
              } else {
                setModalEmailAlreadyExists(true);
              }
            }
          })
          .catch((err) => {
            message.error(t('STEP_2.VERIFY_EMAIL_ERROR'));
          });
      }
    } catch (err) {
      setVerifyingEmail(false);
      console.log(err);
    }
  };

  return (
    <div className={className}>
      {params.get('type') && params.get('type') == 'clocking' ? null : (
        <Button onClick={onPrevious} size="small" type="link" style={{ marginBottom: 25 }}>
          &lt; {t('BACK')}
        </Button>
      )}
      <h1>
        {t(params.get('type') && params.get('type') == 'clocking' ? 'STEP_2.CREATE_YOUR_ACCOUNT' : 'STEP_2.TITLE')}
      </h1>
      <p>{t('STEP_2.DESCRIPTION')}</p>
      {params.get('type') && params.get('type') == 'clocking' ? null : <h2>{t('STEP_2.SUBTITLE')}</h2>}
      <div className="fields">
        <Form.Item
          label={t('STEP_2.FIRSTNAME')}
          name="firstname"
          rules={[{ required: true, message: t('STEP_2.FIRSTNAME_ERROR') }]}
        >
          <Input size="large" placeholder={t('STEP_2.FIRSTNAME')} />
        </Form.Item>
        <Form.Item
          label={t('STEP_2.LASTNAME')}
          name="lastname"
          rules={[{ required: true, message: t('STEP_2.LASTNAME_ERROR') }]}
        >
          <Input size="large" placeholder={t('STEP_2.LASTNAME')} />
        </Form.Item>
        <Form.Item
          label={t('STEP_2.EMAIL')}
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: t('STEP_2.EMAIL_ERROR'),
            },
          ]}
        >
          <div className="email-container">
            <Input size="large" placeholder={t('STEP_2.EMAIL')} onChange={(e) => setEmail(e.target.value)} />
            <Spin spinning={verifyingEmail} />
          </div>
        </Form.Item>
        <Form.Item
          label={t('STEP_2.PHONE')}
          name="phone"
          rules={[{ required: true, message: t('STEP_2.PHONE_ERROR') }]}
        >
          <PhoneInput
            inputClass="ant-input"
            inputStyle={{ width: '100%', height: 40 }}
            placeholder={t('STEP_2.PHONE')}
          />
        </Form.Item>
      </div>

      <Form.Item>
        <Button
          loading={checkingEmail}
          size="large"
          type="primary"
          onClick={async () => {
            const validated = await form.validateFields(['firstname', 'lastname', 'email', 'phone']);
            if (validated) {
              onNext(setCheckingEmail);
            }
          }}
          style={{ width: '100%' }}
        >
          {t('NEXT_STEP')}
        </Button>
      </Form.Item>
      {data && (
        <ModalEmailAlreadyExists
          data={data}
          visible={modalEmailAlreadyExistsVisible}
          setVisible={setModalEmailAlreadyExists}
        />
      )}
      {data && (
        <ModalAccountOnHold data={data} visible={modalAccountOnHoldVisible} setVisible={setModalAccountOnHoldVisible} />
      )}
    </div>
  );
};

export default styled(Step2)`
  position: relative;
  .back {
    cursor: pointer;
  }

  .email-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
