import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import * as doneData from '../doneloading.json';
import * as legoData from '../legoloading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: (legoData as any).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const defaultOptions2 = {
  loop: false,
  autoplay: true,
  animationData: (doneData as any).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface Props {
  className?: string;
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
}

const StepLoading: React.FC<Props> = ({ className, setActiveStep }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  // Create account
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [createdAccount, setCreatedAccount] = useState<boolean>(false);

  // Create settings
  const [creatingSettings, setCreatingSettings] = useState<boolean>(false);
  const [createdSettings, setCreatedSettings] = useState<boolean>(false);

  // Create access
  const [creatingAccess, setCreatingAccess] = useState<boolean>(false);
  const [createdAccess, setCreatedAccess] = useState<boolean>(false);

  // Create data
  const [creatingData, setCreatingData] = useState<boolean>(false);
  const [createdData, setCreatedData] = useState<boolean>(false);

  useEffect(() => {
    setCreatingAccount(true);
    setTimeout(() => {
      setCreatedAccount(true);
      setCreatingSettings(true);
    }, AVG_REQUEST / 4);
    setTimeout(() => {
      setCreatedSettings(true);
      setCreatingAccess(true);
    }, AVG_REQUEST / 2);
    setTimeout(() => {
      setCreatedAccess(true);
      setCreatingData(true);
    }, (AVG_REQUEST / 4) * 3);
    setTimeout(() => {
      setActiveStep('new_trial');
    }, (AVG_REQUEST / 4) * 4);
  }, []);

  return (
    <div className={className}>
      <div>
        {creatingAccount && (
          <FadeIn>
            <div className="loading">
              <h2>{t('STEP_LOADING.ACCOUNT_CREATION')}</h2>
              {!createdAccount ? (
                <Lottie options={defaultOptions} height={120} width={120} />
              ) : (
                <Lottie options={defaultOptions2} height={120} width={120} />
              )}
            </div>
          </FadeIn>
        )}
        {creatingSettings && (
          <FadeIn>
            <div className="loading">
              <h2>{t('STEP_LOADING.SETTINGS_CREATION')}</h2>
              {!createdSettings ? (
                <Lottie options={defaultOptions} height={120} width={120} />
              ) : (
                <Lottie options={defaultOptions2} height={120} width={120} />
              )}
            </div>
          </FadeIn>
        )}
        {creatingAccess && (
          <FadeIn>
            <div className="loading">
              <h2>{t('STEP_LOADING.ACCESS_CREATION')}</h2>
              {!createdAccess ? (
                <Lottie options={defaultOptions} height={120} width={120} />
              ) : (
                <Lottie options={defaultOptions2} height={120} width={120} />
              )}
            </div>
          </FadeIn>
        )}
        {creatingData && (
          <FadeIn>
            <div className="loading">
              <h2>{t('STEP_LOADING.DEMO_CREATION')}</h2>
              {!createdData ? (
                <Lottie options={defaultOptions} height={120} width={120} />
              ) : (
                <Lottie options={defaultOptions2} height={120} width={120} />
              )}
            </div>
          </FadeIn>
        )}
      </div>
    </div>
  );
};

export default styled(StepLoading)`
  .loading {
    transform: scale(0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2  {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 34px;
    }
  }
`;

const AVG_REQUEST = 10000;
